@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600&display=swap');


.App {
  text-align: center;
}


.App {
  display: block;
  margin: 6vh 3vw;
  position: relative;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  line-height: 1.3;
  color: #000;
}

.headers {
  font-style: italic;
  margin: 1vh 0;
  padding: 1vh 0;
  border-bottom: 1px solid;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
}

header {
  height: auto;
  flex-basis: 20%;
  text-align: left;
}

a {
  color: inherit;
  text-decoration: none;
}

ul, p {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.entries {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.entry {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  margin: 0.3vh 0.1vw;
}

.field {
  height: auto;
  flex-basis: 25%;
  overflow: scroll;
}

.small {
  flex-basis: 5%;
}

.medium {
  flex-basis: 20%;
}

.large {
  flex-basis: 50%;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}